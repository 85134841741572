.accordion {
  &-element {
    margin-bottom: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    border-radius: 24px;

    &.active {
      h3 svg {
        transform: rotate(-90deg);
      }
      .accordion-content {
        max-height: 600px;
        opacity: 1;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      padding: 24px;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
      svg {
        transform: rotate(90deg);
        transition: all .3s;
        flex-shrink: 0;
      }
    }
  }

  &-content {
    padding: 0 24px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s;

    p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 16px;
      opacity: 0.6;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
}