@import '../../lib/scss/colors.scss';

.newsletter-wrapper {
  border-top: 1px solid $color-light-grey;

  .newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 72px;
    margin-top: 36px;
    margin-bottom: 36px;

    @media screen and (max-width: 1023px) {
      gap: 36px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .info {
      text-align: right;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
      h2 {
        color: black;
        font-size: 32px;
        line-height: 42px;
        font-weight: 800;
      }

      p {
        font-size: 18px;
        color: #A1A1A1;
      }
    }

    a {
      text-decoration: none;
    }

    button {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: black;
      font-weight: 800;
      position: relative;
      background-color: white;
      padding: 12px 28px;
      border: 3px solid $color-blue;
      text-decoration: none;
      cursor: pointer;
      flex-shrink: 0;
      transition: all .3s;
      @media screen and (max-width: 575px) {
        align-self: stretch;
      }

      &:after {
        content: "";
        position: absolute;
        transition: all .6s;
        width: 70px;
        height: 75px;
        top: -20px;
        left: 55px;
        background: url("../../assets/img/fancy-2.svg") no-repeat;
        background-size: cover;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
      }

      &:hover:not(:disabled) {
        background-color: rgb(232, 240, 254);

        &:after {
          top: -45px;
          visibility: visible;
          opacity: 1;
        }
      }

      svg {
        margin-left: 8px;
      }
    }
  }
}