@import '../../lib/scss/colors.scss';

.menu {
  background-color: black;
  padding: 40px 100px 30px 40px;
  position: absolute;
  top: 0;
  right: -100%;
  transition: all .42s;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  + .hamburger-react {
    width: 50px !important;
    right: 70px !important;
    position: absolute !important;
    transition: all .42s !important;

    @media (max-width: 639px) {
      right: 12px !important;
    }

    &:hover {
      cursor: url("../../assets/img/hand.png") 0 0, auto !important;

      > div {
        background-color: $color-blue !important;
      }
    }

    > div {
      width: 50px !important;
      height: 6px !important;
    }
  }

  &.active {
    right: 0;

    ul li {
      left: 0;
      transition: all .35s ease-in-out;
    }
  }

  ul {
    li {
      margin-bottom: 8px;
      position: relative;

      &:hover {
        a {
          color: $color-blue;
        }
      }

      a {
        font-size: 30px;
        font-weight: 800;
        color: white;
        line-height: 1;
        transition: all .2s ease-in-out;

        &.selected {
          text-decoration: line-through;
          opacity: .3;
        }
      }
    }

    ul {
      margin-top: 16px;
      margin-bottom: 24px;
      padding-left: 20px;

      li a {
        font-size: 20px;
      }
    }
  }

  > ul {
    > li {
      left: 137%;
      transition: none;

      &:nth-child(1) {
        transition-delay: .2s;
      }

      &:nth-child(2) {
        transition-delay: .22s;
      }

      &:nth-child(4) {
        transition-delay: .42s;
      }

      &:nth-child(5) {
        transition-delay: .44s;
      }

      &:nth-child(6) {
        transition-delay: .46s;
      }

      &:nth-child(7) {
        transition-delay: .48s;
      }

      &:nth-child(8) {
        transition-delay: .5s;
      }

      &:nth-child(9) {
        transition-delay: .52s;
      }

      &:last-child {
        margin-top: 16px;

        a {
          font-size: 20px;
          opacity: .3;
        }
      }
    }

    > ul {
      li {
        left: 140%;
        transition: none;

        &:nth-child(1) {
          transition-delay: .24s;
        }

        &:nth-child(2) {
          transition-delay: .26s;
        }

        &:nth-child(3) {
          transition-delay: .28s;
        }

        &:nth-child(4) {
          transition-delay: .3s;
        }

        &:nth-child(5) {
          transition-delay: .32s;
        }

        &:nth-child(6) {
          transition-delay: .34s;
        }

        &:nth-child(7) {
          transition-delay: .36s;
        }

        &:nth-child(8) {
          transition-delay: .38s;
        }

        &:nth-child(9) {
          transition-delay: .4s;
        }
      }
    }
  }

  &-cover-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
  }
}