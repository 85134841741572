@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GTWalsheim";
    src: url("./assets/fonts/GTWalsheim-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "GTWalsheim";
    src: url("./assets/fonts/GTWalsheim-Medium.woff2") format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: "GTWalsheim";
    src: url("./assets/fonts/GTWalsheim-Black.woff2") format("woff2");
    font-weight: 800;
}

body {
    font-family: "GTWalsheim";
    @media screen and (max-width: 600px) {
        overflow: auto !important;
    }
}

#root {
    height: 100%;
}