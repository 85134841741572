@import '../../lib/scss/colors.scss';


.image {
  margin-bottom: 80px;

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }

  @media (max-width: 575px) {
    margin-bottom: 50px;
  }

  &.with-background {
    background-color: $color-turquoise;
    padding: 80px 150px;

    @media (max-width: 1399px) {
      padding: 70px 120px;
    }

    @media (max-width: 1199px) {
      padding: 60px 100px;
    }

    @media (max-width: 991px) {
      padding: 50px 70px;
    }

    @media (max-width: 767px) {
      padding: 35px 50px;
    }

    @media (max-width: 575px) {
      padding: 20px;
    }
  }

  &.caption {
    padding-bottom: 32px;

    @media (max-width: 575px) {
      padding-bottom: 20px;
    }
  }

  img {
    width: 100%;
    border-radius: 4px;
  }

  &-caption {
    font-size: 14px;
    line-height: 22px;
    display: block;
    margin-top: 32px;

    @media (max-width: 991px) {
      margin-top: 24px;
    }

    @media (max-width: 575px) {
      margin-top: 20px;
    }

    &::before {
      content: "\A";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: black;
      display: inline-block;
      margin-right: 5px;
    }
  }
}