.main-page {
  padding-top: 170px;
  margin-bottom: 170px;
  @media screen and (max-width: 767px) {
    margin-bottom: 80px;
    padding: 120px 12px 0;
  }

  &-header {
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 120px;
    @media screen and (max-width: 1279px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 1023px) {
      margin-bottom: 60px;
      font-size: 42px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
      font-size: 36px;
    }
  }
}