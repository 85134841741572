@import '../../lib/scss/colors.scss';

.header {
  padding-top: 36px;
  padding-bottom: 36px;
  background-color: white;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid white;
  transform: translateY(-100%);
  transition: all .3s;
  z-index: 10;
  &.transparent {
    background-color: transparent;
    border-bottom: none;
  }

  @media (max-width: 639px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &.collapsed {
    border-bottom: 1px solid #D8D8D8;
  }

  &.pinned {
    transform: translateY(0);
  }

  a svg:hover {
    fill: #7c59ed
  }
  .header-logo {
    &.centered {
      margin: 0 auto;
    }
  }
}