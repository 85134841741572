@import '../../lib/scss/colors.scss';

.masonry-wall {
  display: grid;
  grid-template-columns: repeat(24, 1fr);

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
  }

  .item {
    height: 100%;
    position: relative;
    overflow: hidden;

    &.red {
      background-color: $color-red;
    }

    &.blue {
      background-color: $color-blue;
    }

    &.text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 26px 30px;
      @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding: 14px 18px;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 10px 14px;
      }

      h3 {
        font-size: 22px;
        font-weight: 800;
        color: white;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 20px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 18px;
          line-height: 1.1em;

        }
      }

      p {
        font-size: 18px;
        color: white;
        margin-top: 12px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 16px;
          margin-top: 0;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 14px;
          margin-top: 0;
          line-height: 1.3em;
        }
      }

      button {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: white;
        margin-top: 16px;
        padding: 12px 28px;
        border: 3px solid white;

        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 16px;
          margin-top: 12px;
          padding: 6px 20px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          border: none;
          font-size: 14px;
          padding: 0;
          margin-top: 4px;
        }

        svg {
          width: 13px;
          height: 12px;
          margin-left: 7px;
        }
      }
    }

    &.video {
      video, .video, .video .video-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        position: absolute;
        left: 40px;
        bottom: 40px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: white;
        margin-top: auto;
        padding: 12px 28px;
        border: 3px solid white;
        cursor: pointer;
        @media screen and (max-width: 1279px) {
          font-size: 16px;
          padding: 10px 24px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          border: none;
          padding: 0;
        }

        svg {
          width: 29px;
          height: 29px;
          margin-left: 7px;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    &.rotate-left {
      transform: rotate(-4deg);
      @media screen and (max-width: 767px) {
        transform: rotate(-2deg);
      }
    }

    &.rotate-right {
      transform: rotate(4deg);
      @media screen and (max-width: 767px) {
        transform: rotate(2deg);
      }
    }

    &:after {
      content: "";
      position: absolute;
      transition: all .6s;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  &.set-1 {
    grid-template-rows: repeat(15, 16.6px);
    gap: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 1535px) {
      grid-template-rows: repeat(15, 24.5px);
      gap: 30px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 1279px) {
      grid-template-rows: repeat(15, 23.5px);
      gap: 20px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1023px) {
      grid-template-rows: repeat(15, 22.4px);
      gap: 10px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 767px) {
      grid-template-rows: repeat(41, 39.3px);
      gap: 20px;
      margin-bottom: 20px;
    }

    .item-1 {
      grid-column: 1 / span 6;
      grid-row: 1 / span 8;

      &:after {
        width: 161px;
        height: 177px;
        top: -177px;
        left: 50px;
        background: url("../../assets/img/fancy-1.svg") no-repeat;
      }

      &:hover {
        &:after {
          top: 0;
        }
      }
    }

    .item-2 {
      grid-column: 7 / span 4;
      grid-row: 1 / span 4;
    }

    .item-3 {
      grid-column: 11 / span 6;
      grid-row: 1 / span 3;
    }

    .item-4 {
      grid-column: 17 / span 8;
      grid-row: 1 / span 4;
      overflow: visible;

      &:after {
        width: 137px;
        height: 146px;
        top: 0;
        right: 0;
        background: url("../../assets/img/fancy-2.svg") no-repeat;
        z-index: -1;
      }

      &:hover {
        &:after {
          top: -75px;
        }
      }
    }

    .item-5 {
      grid-column: 7 / span 4;
      grid-row: 5 / span 4;
    }

    .item-6 {
      grid-column: 11 / span 6;
      grid-row: 4 / span 4;
    }

    .item-7 {
      grid-column: 17 / span 2;
      grid-row: 5 / span 3;
    }

    .item-8 {
      grid-column: 19 / span 6;
      grid-row: 5 / span 7;
    }

    .item-9 {
      grid-column: 1 / span 4;
      grid-row: 9 / span 7;
    }

    .item-10 {
      grid-column: 5 / span 6;
      grid-row: 9 / span 3;
    }

    .item-11 {
      grid-column: 11 / span 8;
      grid-row: 8 / span 4;

      &:after {
        width: 145px;
        height: 175px;
        right: -145px;
        background: url("../../assets/img/fancy-3.svg") no-repeat;
      }

      &:hover {
        &:after {
          right: 0;
        }
      }
    }

    .item-12 {
      grid-column: 5 / span 4;
      grid-row: 12 / span 4;
    }

    .item-13 {
      grid-column: 9 / span 10;
      grid-row: 12 / span 4;
    }

    .item-14 {
      grid-column: 19 / span 6;
      grid-row: 12 / span 4;
    }

    @media screen and (max-width: 767px) {
      .item-1 {
        grid-column: 1 / span 6;
        grid-row: 1 / span 4;
      }
      .item-2 {
        grid-column: 1 / span 3;
        grid-row: 5 / span 3;
      }
      .item-3 {
        grid-column: 1 / span 6;
        grid-row: 8 / span 3;
      }
      .item-4 {
        grid-column: 1 / span 6;
        grid-row: 15 / span 4;
      }
      .item-5 {
        grid-column: 4 / span 3;
        grid-row: 5 / span 3;
      }
      .item-6 {
        grid-column: 1 / span 6;
        grid-row: 11 / span 4;
      }
      .item-7 {
        grid-column: 1 / span 2;
        grid-row: 19 / span 2;
      }
      .item-8 {
        grid-column: 3 / span 4;
        grid-row: 19 / span 5;
      }
      .item-9 {
        grid-column: 1 / span 2;
        grid-row: 21 / span 3;
      }
      .item-10 {
        grid-column: 1 / span 6;
        grid-row: 24 / span 3;
      }
      .item-11 {
        grid-column: 1 / span 6;
        grid-row: 31 / span 4;
      }
      .item-12 {
        grid-column: 1 / span 6;
        grid-row: 27 / span 4;
      }
      .item-13 {
        grid-column: 1 / span 6;
        grid-row: 35 / span 3;
      }
      .item-14 {
        grid-column: 1 / span 6;
        grid-row: 38 / span 4;
      }
    }
  }

  &.set-2 {
    grid-template-rows: repeat(23, 16.6px);
    gap: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 1535px) {
      grid-template-rows: repeat(23, 24.5px);
      gap: 30px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 1279px) {
      grid-template-rows: repeat(23, 23.5px);
      gap: 20px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1023px) {
      grid-template-rows: repeat(23, 22.4px);
      gap: 10px;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: repeat(61, 39.3px);
      gap: 20px;
      margin-bottom: 20px;
    }

    .item-1 {
      grid-column: 1 / span 14;
      grid-row: 1 / span 8;
    }

    .item-2 {
      grid-column: 15 / span 2;
      grid-row: 1 / span 8;
    }

    .item-3 {
      grid-column: 17 / span 8;
      grid-row: 1 / span 4;

      &:after {
        width: 113px;
        height: 113px;
        top: 0;
        right: -113px;
        background: url("../../assets/img/fancy-4.svg") no-repeat;
      }

      &:hover {
        &:after {
          right: 0;
        }
      }
    }

    .item-4 {
      grid-column: 17 / span 8;
      grid-row: 5 / span 4;
    }

    .item-5 {
      grid-column: 1 / span 5;
      grid-row: 9 / span 3;
    }

    .item-6 {
      grid-column: 6 / span 9;
      grid-row: 9 / span 3;
    }

    .item-7 {
      grid-column: 15 / span 4;
      grid-row: 9 / span 4;
    }

    .item-8 {
      grid-column: 19 / span 6;
      grid-row: 9 / span 8;

      &:after {
        width: 179px;
        height: 147px;
        top: -147px;
        right: 40px;
        background: url("../../assets/img/fancy-5.svg") no-repeat;
      }

      &:hover {
        &:after {
          top: 0;
        }
      }
    }

    .item-9 {
      grid-column: 1 / span 8;
      grid-row: 12 / span 4;
    }

    .item-10 {
      grid-column: 9 / span 6;
      grid-row: 12 / span 4;
    }

    .item-11 {
      grid-column: 15 / span 4;
      grid-row: 13 / span 4;
    }

    .item-12 {
      grid-column: 1 / span 6;
      grid-row: 16 / span 8;
    }

    .item-13 {
      grid-column: 7 / span 8;
      grid-row: 16 / span 4;

      &:after {
        width: 172px;
        height: 99px;
        bottom: -99px;
        right: 10px;
        background: url("../../assets/img/fancy-6.svg") no-repeat;
      }

      &:hover {
        &:after {
          bottom: 0;
        }
      }
    }

    .item-14 {
      grid-column: 15 / span 6;
      grid-row: 17 / span 3;
    }

    .item-15 {
      grid-column: 21 / span 4;
      grid-row: 17 / span 7;
    }

    .item-16 {
      grid-column: 7 / span 10;
      grid-row: 20 / span 4;
    }

    .item-17 {
      grid-column: 17 / span 4;
      grid-row: 20 / span 4;
    }

    @media screen and (max-width: 767px) {
      .item-1 {
        grid-column: 1 / span 6;
        grid-row: 1 / span 9;
      }
      .item-2 {
        grid-column: 1 / span 6;
        grid-row: 10 / span 2;
      }
      .item-3 {
        grid-column: 1 / span 6;
        grid-row: 12 / span 4;
      }
      .item-4 {
        grid-column: 1 / span 6;
        grid-row: 16 / span 3;
      }
      .item-5 {
        grid-column: 1 / span 6;
        grid-row: 19 / span 3;
      }
      .item-6 {
        grid-column: 1 / span 6;
        grid-row: 25 / span 2;
      }
      .item-7 {
        grid-column: 1 / span 3;
        grid-row: 31 / span 3;
      }
      .item-8 {
        grid-column: 1 / span 6;
        grid-row: 34 / span 4;
      }
      .item-9 {
        grid-column: 1 / span 6;
        grid-row: 22 / span 3;
      }
      .item-10 {
        grid-column: 1 / span 6;
        grid-row: 27 / span 4;
      }
      .item-11 {
        grid-column: 4 / span 3;
        grid-row: 31 / span 3;
      }
      .item-12 {
        grid-column: 1 / span 6;
        grid-row: 38 / span 9;
      }
      .item-13 {
        grid-column: 1 / span 6;
        grid-row: 47 / span 4;
      }
      .item-14 {
        grid-column: 1 / span 6;
        grid-row: 54 / span 3;
      }
      .item-15 {
        grid-column: 4 / span 3;
        grid-row: 57 / span 5;
      }
      .item-16 {
        grid-column: 1 / span 6;
        grid-row: 51 / span 3;
      }
      .item-17 {
        grid-column: 1 / span 3;
        grid-row: 57 / span 5;
      }
    }
  }

  &.set-3 {
    grid-template-rows: repeat(12, 16.6px);
    gap: 40px;
    @media screen and (max-width: 1535px) {
      grid-template-rows: repeat(12, 24.5px);
      gap: 30px;
    }
    @media screen and (max-width: 1279px) {
      grid-template-rows: repeat(12, 23.5px);
      gap: 20px;
    }
    @media screen and (max-width: 1023px) {
      grid-template-rows: repeat(12, 22.4px);
      gap: 10px;
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: repeat(43, 39.3px);
      gap: 20px;
      margin-bottom: 20px;
    }

    .item-1 {
      grid-column: 1 / span 8;
      grid-row: 1 / span 8;

      &:after {
        width: 286px;
        height: 186px;
        top: -186px;
        right: 0;
        background: url("../../assets/img/fancy-7.svg") no-repeat;
      }

      &:hover {
        &:after {
          top: 0;
        }
      }
    }

    .item-2 {
      grid-column: 9 / span 4;
      grid-row: 1 / span 4;
    }

    .item-3 {
      grid-column: 13 / span 8;
      grid-row: 1 / span 4;

      &:after {
        width: 113px;
        height: 156px;
        bottom: -156px;
        right: 0;
        background: url("../../assets/img/fancy-8.svg") no-repeat;
      }

      &:hover {
        &:after {
          bottom: 0;
        }
      }
    }

    .item-4 {
      grid-column: 21 / span 4;
      grid-row: 1 / span 4;
    }

    .item-5 {
      grid-column: 9 / span 5;
      grid-row: 5 / span 4;
    }

    .item-6 {
      grid-column: 14 / span 11;
      grid-row: 5 / span 4;
    }

    .item-7 {
      grid-column: 1 / span 6;
      grid-row: 9 / span 4;
    }

    .item-8 {
      grid-column: 7 / span 4;
      grid-row: 9 / span 4;
    }

    .item-9 {
      grid-column: 11 / span 6;
      grid-row: 9 / span 4;
    }

    .item-10 {
      grid-column: 17 / span 8;
      grid-row: 9 / span 4;
      overflow: visible;

      &:after {
        width: 192px;
        height: 191px;
        bottom: 0;
        right: 0;
        background: url("../../assets/img/fancy-9.svg") no-repeat;
        z-index: -1;
      }

      &:hover {
        &:after {
          bottom: -135px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .item-1 {
        grid-column: 1 / span 6;
        grid-row: 1 / span 4;
      }
      .item-2 {
        grid-column: 1 / span 6;
        grid-row: 5 / span 4;
      }
      .item-3 {
        grid-column: 1 / span 6;
        grid-row: 13 / span 4;
      }
      .item-4 {
        grid-column: 1 / span 6;
        grid-row: 20 / span 7;
      }
      .item-5 {
        grid-column: 1 / span 6;
        grid-row: 9 / span 4;
      }
      .item-6 {
        grid-column: 1 / span 6;
        grid-row: 17 / span 3;
      }
      .item-7 {
        grid-column: 1 / span 6;
        grid-row: 27 / span 4;
      }
      .item-8 {
        grid-column: 1 / span 6;
        grid-row: 31 / span 5;
      }
      .item-9 {
        grid-column: 1 / span 6;
        grid-row: 36 / span 4;
      }
      .item-10 {
        grid-column: 1 / span 6;
        grid-row: 40 / span 4;
      }
    }
  }

  &.set-element {
    grid-template-rows: repeat(16, 16.6px);
    gap: 40px;
    @media screen and (max-width: 1535px) {
      gap: 30px;
      grid-template-rows: repeat(16, 24.5px);
    }
    @media screen and (max-width: 1279px) {
      gap: 20px;
      grid-template-rows: repeat(16, 23.5px);
    }
    @media screen and (max-width: 1023px) {
      gap: 10px;
      grid-template-rows: repeat(16, 22.4px);
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: repeat(43, 39.3px);
      gap: 20px;
      margin-bottom: 20px;
    }

    .item-1 {
      grid-column: 1 / span 12;
      grid-row: 1 / span 4;

      &:after {
        width: 172px;
        height: 99px;
        bottom: -99px;
        right: 40px;
        background: url("../../assets/img/fancy-6.svg") no-repeat;
      }

      &:hover {
        &:after {
          bottom: 0;
        }
      }
    }

    .item-2 {
      grid-column: 13 / span 4;
      grid-row: 1 / span 4;
    }

    .item-3 {
      grid-column: 17 / span 8;
      grid-row: 1 / span 4;
    }

    .item-4 {
      grid-column: 1 / span 6;
      grid-row: 5 / span 8;
    }

    .item-5 {
      grid-column: 7 / span 6;
      grid-row: 5 / span 8;
    }

    .item-6 {
      grid-column: 13 / span 12;
      grid-row: 5 / span 12;
    }

    .item-7 {
      grid-column: 1 / span 4;
      grid-row: 13 / span 4;
    }

    .item-8 {
      grid-column: 5 / span 8;
      grid-row: 13 / span 4;
    }

    @media screen and (max-width: 767px) {
      .item-1 {
        grid-column: 1 / span 6;
        grid-row: 1 / span 4;
      }
      .item-2 {
        grid-column: 1 / span 6;
        grid-row: 5 / span 4;
      }
      .item-3 {
        grid-column: 1 / span 6;
        grid-row: 9 / span 3;
      }
      .item-4 {
        grid-column: 1 / span 6;
        grid-row: 12 / span 8;
      }
      .item-5 {
        grid-column: 1 / span 6;
        grid-row: 20 / span 8;
      }
      .item-6 {
        grid-column: 1 / span 6;
        grid-row: 28 / span 9;
      }
      .item-7 {
        grid-column: 1 / span 6;
        grid-row: 37 / span 4;
      }
      .item-8 {
        grid-column: 1 / span 6;
        grid-row: 41 / span 3;
      }
    }
  }
}