@import '../../lib/scss/colors.scss';

.element-page {
  padding-top: 170px;
  margin-bottom: 120px;
  @media screen and (max-width: 767px) {
    margin-bottom: 80px;
    padding: 120px 12px 0;
  }

  &-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 120px;
    padding-right: 16%;
    @media screen and (max-width: 1279px) {
      padding-right: 12%;
    }
    @media screen and (max-width: 1023px) {
      padding: 0;
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 80px;
    }

    .element-page-link {
      margin-top: 24px
    }

    div {
      padding-left: 16%;
      @media screen and (max-width: 1279px) {
        padding-left: 12%;
      }

      @media screen and (max-width: 1023px) {
        padding-left: 0;
      }

      h1 {
        font-size: 50px;
        font-weight: 800;
        margin-bottom: 80px;
        @media screen and (max-width: 1279px) {
          margin-bottom: 60px;
        }
        @media screen and (max-width: 1023px) {
          margin-bottom: 50px;
          font-size: 42px;
        }
        @media screen and (max-width: 767px) {
          margin-bottom: 40px;
          font-size: 36px;
        }
      }

      h3 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 20px;
      }

      p {
        font-size: 22px;
        color: $color-grey;
        margin-bottom: 16px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
  }

  &-link-header {
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    margin-top: 0;
    width: auto;
    z-index: 20;
    margin-left: 100px;
    transition: all .3s;
    opacity: 0;

    @media (min-width: 1280px) {
      margin-left: 85px
    }

    &.visible {
      opacity: 1;
    }

    &.pinned {
      transform: translateY(0);
      margin-top: 45px;

      @media (max-width: 639px) {
        margin-top: 35px;
      }
    }
  }

  .sections {
    max-width: 992px;
    margin: 0 auto;
    .section {
      margin-top: 72px;

      .text {
        h2 {
          font-size: 32px;
          line-height: 42px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          margin-bottom: 16px;
          opacity: .6;
        }
      }
    }
  }

  &-link {
    font-size: 18px;
    line-height: 26px;
    color: black !important;
    opacity: .37;
    text-decoration: none;
    display: flex;
    align-items: center;

    @media (max-width: 1023px) {
      margin-bottom: 32px;
    }

    &:hover {
      color: #0d6efd !important
    }

    &.disabled {
      pointer-events: none;
      opacity: .1;
    }

    svg {
      margin: 0 12px;

      @media (max-width: 1279px) {
        margin-left: 0;
      }
    }
  }

  .faq-section {
    display: flex;
    gap: 72px;
    margin-top: 72px;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      gap: 16px;
    }

    > div {
      &:first-child {
        flex-basis: 30%;
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }

        h2 {
          font-size: 32px;
          line-height: 42px;
          font-weight: 800;
          margin-bottom: 20px;
        }

        p {
          font-size: 18px;
          margin-bottom: 16px;
          opacity: .6;
        }
      }

      &:last-child {
        flex-basis: 70%;
        @media screen and (max-width: 767px) {
          flex-basis: 100%;
        }
      }
    }
  }

  .other-skills {
    margin-top: 120px;
    @media screen and (max-width: 767px) {
      margin-top: 80px;
    }

    h2 {
      font-size: 50px;
      font-weight: 800;
      margin-bottom: 80px;
      text-align: center;
      @media screen and (max-width: 1279px) {
        margin-bottom: 60px;
      }
      @media screen and (max-width: 1023px) {
        margin-bottom: 50px;
        font-size: 42px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 40px;
        font-size: 36px;
      }
    }

    .skills-masonry {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      @media screen and (max-width: 1535px) {
        gap: 30px;
      }
      @media screen and (max-width: 1279px) {
        gap: 20px;
      }
      @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
      @media screen and (max-width: 639px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .skill {
      aspect-ratio: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 30px;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: 1023px) {
        aspect-ratio: 1.5/1;
      }
      @media screen and (max-width: 767px) {
        aspect-ratio: 1.2/1;
      }
      @media screen and (max-width: 639px) {
        aspect-ratio: auto;
      }

      &:after {
        content: "";
        position: absolute;
        transition: all .6s;
        @media screen and (max-width: 639px) {
          display: none;
        }
      }

      &.skill-1 {
        background-color: $color-red;
        overflow: visible;

        &:after {
          width: 137px;
          height: 146px;
          top: 0;
          background: url("../../assets/img/fancy-2.svg") no-repeat;
          z-index: -1;
        }

        &:hover {
          &:after {
            top: -75px;
          }
        }
      }

      &.skill-2 {
        background-color: $color-blue;

        &:after {
          width: 145px;
          height: 175px;
          right: -175px;
          top: -25px;
          background: url("../../assets/img/fancy-3.svg") no-repeat;
        }

        &:hover {
          &:after {
            right: -10px;
          }
        }
      }

      &.skill-3 {
        background-color: $color-red;

        &:after {
          width: 113px;
          height: 113px;
          top: 0;
          right: -113px;
          background: url("../../assets/img/fancy-4.svg") no-repeat;
        }

        &:hover {
          &:after {
            right: 0;
          }
        }
      }

      &.skill-4 {
        background-color: $color-blue;

        &:after {
          width: 156px;
          height: 126px;
          top: 0;
          right: -156px;
          background: url("../../assets/img/fancy-10.svg") no-repeat;
        }

        &:hover {
          &:after {
            right: 0;
            @media screen and (min-width: 1024px) and (max-width: 1279px) {
              top: -40px;
              right: -20px;
            }
          }
        }
      }

      &.skill-5 {
        background-color: $color-blue;

        &:after {
          width: 133px;
          height: 160px;
          top: 0;
          right: -133px;
          background: url("../../assets/img/fancy-11.svg") no-repeat;
        }

        &:hover {
          &:after {
            right: 0;
            top: 20px;
            @media screen and (min-width: 1024px) and (max-width: 1279px) {
              top: -10px;
              right: -30px;
            }
          }
        }
      }

      &.skill-6 {
        background-color: $color-red;

        &:after {
          width: 206px;
          height: 86px;
          top: -85px;
          right: 0;
          background: url("../../assets/img/fancy-12.svg") no-repeat;
        }

        &:hover {
          &:after {
            top: 0;
          }
        }
      }

      &.skill-7 {
        background-color: $color-blue;

        &:after {
          width: 199px;
          height: 122px;
          top: -122px;
          right: 0;
          background: url("../../assets/img/fancy-13.svg") no-repeat;
        }

        &:hover {
          &:after {
            top: 0;
          }
        }
      }

      &.skill-8 {
        background-color: $color-red;
        overflow: visible;

        &:after {
          width: 192px;
          height: 191px;
          bottom: 0;
          right: 0;
          background: url("../../assets/img/fancy-9.svg") no-repeat;
          transform: rotate(180deg);
          z-index: -1;
        }

        &:hover {
          &:after {
            bottom: -135px;
            right: -20px;
            transform: rotate(360deg);
          }
        }
      }

      h3 {
        font-size: 22px;
        font-weight: 800;
        color: white;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 18px;
        color: white;
        margin-top: 12px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 16px;
          margin-top: 8px;
        }
      }

      button {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: white;
        margin-top: 25px;
        padding: 12px 28px;
        border: 3px solid white;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
          font-size: 16px;
          margin-top: 20px;
          padding: 10px 24px;
        }

        svg {
          width: 13px;
          height: 12px;
          margin-left: 7px;
        }
      }
    }
  }
}