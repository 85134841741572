@import '../../lib/scss/colors.scss';

.footer {
  border-top: 1px solid $color-light-grey;
  padding: 30px 0;

  @media (max-width: 639px) {
    padding: 25px 8px;
  }

  &-content {
    display: flex;
    justify-content: space-between;

    @media (max-width: 639px) {
      flex-wrap: wrap;
    }
  }

  &-address {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 26px;
    opacity: .37;
    font-style: normal;

    @media (max-width: 639px) {
      font-size: 16px;
      flex-basis: 100%;
      margin-bottom: 6px;
    }
  }

  &-links {
    ul {
      margin: 0;
      padding: 0;
      display: flex;

      li {
        list-style: none;
        //due to main.css from the server
        position: static;
        margin-bottom: 0;

        &:first-child {
          a {
            @media (max-width: 639px) {
              margin-left: 12px;
            }
          }
        }

        a {
          color: black;
          font-size: 18px;
          line-height: 26px;
          margin-left: 16px;
          opacity: .37;
          cursor: pointer !important;

          @media (max-width: 639px) {
            font-size: 16px;
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}